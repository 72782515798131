<template>
  <div class="has_declare">
    <div class="head">
      <img src="../../../../../assets/pc/images/policy/shenbao.png" alt="">
      <div>
        <div>请留下您的申报需求</div>
        <div>我们会在第一时间给您提供申报服务</div>
      </div>
    </div>
    <el-form :model="declareForm"  ref="declareFormRef" :rules="shenbaoRules" label-position="top">
      <el-form-item prop="entityName" label="申报主体（企业名称）">
        <el-input v-model="declareForm.entityName" placeholder="请输入企业主体的名称"></el-input>
      </el-form-item>
      <el-form-item required>
        <el-col :span="11" style="margin-right:8%">
          <el-form-item prop="contantName" label="联系人">
            <el-input v-model="declareForm.contantName" placeholder="请输入联系人姓名"></el-input>
          </el-form-item>
        </el-col>
          <el-col :span="11">
          <el-form-item prop="contantPhone" label="电话">
            <el-input v-model="declareForm.contantPhone" placeholder="请输入联系人电话" maxlength="11"></el-input>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item label="备注">
        <el-input type="textarea" v-model="declareForm.remark" placeholder="请输入你的需求"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer footer" style="text-align: center;">
      <el-button type="primary" @click="shenbaoEvent">提交</el-button>
    </div>
  </div>
</template>

<script>
import { addHasDeclare } from '@/api/simple_matching/index';
import { getMemberId, getMobile } from '@/assets/public/utils/token';
import { mapState } from 'vuex';
import { request } from "@/network";
export default {
  props: {
    config: Object
  },
  data () {
    return {
      declareForm: {
        entityName: '',
        contantName: '',
        contantPhone: '',
        remark: ''
      },
      shenbaoRules: {
        entityName: [
          { required: true, message: '请输入企业主体的名称', trigger: 'blur' }
        ],
        contantName: [
          { required: true, message: '请输入联系人姓名', trigger: 'blur' }
        ],
        contantPhone: [
          { required: true, pattern: '^[1][3456789][0-9]{9}$', message: '请输入正确联系人电话', trigger: 'blur' }
        ]
      }
    };
  },
  computed: {
    ...mapState('simpleMatching', [ 'companyName' ])
  },
  mounted () {
    this.declareForm.entityName = this.companyName;
    this.declareForm.contantPhone = getMobile();
    this.getDeclareLog()
  },
  methods: {
    shenbaoEvent () {
      const _this = this;
      console.log(_this.declareForm)
      this.$refs.declareFormRef.validate(async valid => {
        if (valid) {
          addHasDeclare(
            {
              entityName: _this.declareForm.entityName,
              contantName: _this.declareForm.contantName,
              contantPhone: _this.declareForm.contantPhone,
              zhengceId: _this.config.policyId,
              demandDescription:_this.declareForm.remark,
              memberId: getMemberId()
            }
          ).then(res => {
            if (res.data.code !== 200) return _this.$message.error('提交失败');
            _this.$message({
              message: '提交成功，平台客服稍后会与您联系',
              type: 'success'
            });
            _this.$emit('close');
          });
        }
      });
    },
    async getDeclareLog (pageNo, pageSize) {
      const { data: res } = await request({
        method: 'POST',
        url: '/pcp/PersonalCenter/getDeclareLog',
        data: {
          memberId: getMemberId(),
          pageNo: 1,
          pageSize: 10
        }
      });
      if (res.code !== 200) this.$message.error('获取申报记录失败');
      this.declareForm.contantName=res.data.list[0].contantName
    },
  }
};
</script>

<style scoped lang="less">
.head {
  display: flex;
  img {
    margin-right: 10px;
  }
  div {
    color: #156ED0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}
.el-form {
  margin-top: 30px;
  .el-form-item {
    margin-bottom: 0;
    .el-form-item__content {
      font-size: 16px;
    }
  }
}
/deep/.el-form-item {
  width: 100% !important;
}
.footer {
  margin-top: 20px;
}
</style>
