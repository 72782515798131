<template>
  <div>
    <!-- <div slot="title" class="header-title header">
      <div>
        <span class="header_title">{{config.title}}</span>
        <span class="header_num">共{{config.num}}项</span>
      </div>
    </div> -->
    <div class="content">
      <div
        v-for="(item, index) in config.list"
        :key="index"
        class="content_item"
      >
        {{item}}
      </div>
    </div>
    <!-- <div slot="footer"></div> -->
  </div>
</template>

<script>
export default {
  props: {
    config: Object
  },
  data () {
    return {
      list: [
        '独角兽企业',
        '独角兽企业',
        '独角兽企业',
        '独角兽企业',
        '独角兽企业'
      ]
    };
  }
};
</script>

<style scoped lang="less">
.header {
  .header_title {
    color: rgba(51, 51, 51, 1);
    font-size: 20px;
    font-weight: bold;
  }
  .header_num {
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    margin-left: 13px;
  }
}
.content {
  // border-top: 1px solid rgba(187, 187, 187, 0.5);
  .content_item {
    margin-bottom: 20px;
  }
}
</style>
